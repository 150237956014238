import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  InputGroup,
  ButtonGroup,
  ToggleButton,
  Button,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../Utils/api";
import Loader from "../Common/Loader ";
import QRCode from "react-qr-code";

const emptyTransactionData = {
  TransactionID: 0,
  CustomerID: null,
  CustomerName: "",
  AppName: "",
};

export default function Transaction() {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyTransactionData);
  const [amount, setAmount] = useState(null);
  const [firstCardBody, setFirstCardBody] = useState(true);
  const [SecondCardBody, setSecondCardBody] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [QRLink, setQRLink] = useState(null);
  const [GoodlePayDeepLink, setGoodlePayDeepLink] = useState(null);
  const [PaytmDeepLink, setPaytmDeepLink] = useState(null);
  const [BHIMDeepLink, setBHIMDeepLink] = useState(null);
  const [PhonePeDeepLink, setPhonePeDeepLink] = useState(null);
  const [UPIDeepLink, setUPIDeepLink] = useState(null);
  const [QRCardBody, setQRCardBody] = useState(false);
  const [timeLeft, setTimeLeft] = useState(150);
  const [TransactionIDOfQR, setTransactionIDOfQR] = useState(null);
  const [upiID, setUpiID] = useState(null);


  useEffect(()=>{
    var UpiID = localStorage.getItem("IDforTransaction")
    if(UpiID){
      setUpiID(UpiID);
    }
  }, [])

  useEffect(() => {
    let timer;

    if (QRCardBody && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime > 1) {
            // Call CheckStatus every 3 seconds
            if ((timeLeft - 1) % 3 === 0) {
              CheckStatus();
            }
            return prevTime - 1;
          } else {
            // Clear everything when timeLeft reaches 0
            clearAll();
            return 0;
          }
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [QRCardBody, timeLeft]);

  const CheckStatus = () => {
    debugger;
    api
      .get(
        `Transaction/StatusOfCurrentTransaction?TransactionID=${TransactionIDOfQR}`
      )
      .then((result) => {
        var data = result.data.data;
        if (data == "Success") {
          toast.success("Transaction has been completed");
          clearAll();
        }
      })
      .catch((error) => {
        toast.error("An error occurred");
      });
  };

  const clearAll = () => {
    setValidated(false);
    setFormData(emptyTransactionData);
    setAmount(null);
    setFirstCardBody(true);
    setSecondCardBody(false);
    setQRLink(null);
    setQRCardBody(false);
    setTimeLeft(150);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAppNameChange = (appName) => {
    setFormData({ ...formData, AppName: appName });
  };

  const handleFirstPart = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (formData.AppName === "") {
      toast.error("Please select an app");
      return;
    }
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    setValidated(true);
    setFirstCardBody(false);
    setSecondCardBody(true);
  };

  const handleFinalSubmit = () => {
    setIsLoading(true);
debugger
    if(upiID == null){
      toast.error("Select upi ID");
      setIsLoading(false);
      return;
    }

    if (amount == null || amount <= 0) {
      toast.error("Select Valid Amount");
      setIsLoading(false);
      return;
    }

    const data = {
      CustomerID: 1,
      CustomerName: "User",
      AppName: formData.AppName,
      Amount: amount,
      UpiIDfForTransaction: upiID,
    };

    api
      .post("Transaction/InsertTransaction", data)
      .then((result) => {
        if (result.status === 200) {
          toast.success(result.data.message);
          console.log(result.data.data);
          setQRLink(result.data.data.qr);
          setGoodlePayDeepLink(result.data.data.goodlePayDeepLink);
          setPaytmDeepLink(result.data.data.paytmDeepLink);
          setBHIMDeepLink(result.data.data.bHIMDeepLink);
          setPhonePeDeepLink(result.data.data.phonePeDeepLink);
          setUPIDeepLink(result.data.data.uPIDeepLink); 
          setTransactionIDOfQR(result.data.data.transactionID);
          setSecondCardBody(false);
          setQRCardBody(true);
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <ToastContainer />
      {isLoading && <Loader />}
      <Card>
        <Card.Header>
          <span>Transaction</span>
        </Card.Header>
        {firstCardBody && (
          <Card.Body>
            <Form noValidate validated={validated}>
              <Row className="mb-3">
                {/* <Form.Group
                  as={Col}
                  md="4"
                  controlId="validationCustomCustomerID"
                >
                  <Form.Label>CustomerID</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="number"
                      placeholder="CustomerID"
                      name="CustomerID"
                      value={formData.CustomerID}
                      onChange={handleInputChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please choose a CustomerID.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group> */}
                <Form.Group
                  as={Col}
                  md="4"
                  controlId="validationCustomCustomerName"
                >
                  <Form.Label>UpiID ToTransfer</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      placeholder="upiID"
                      name="upiID"
                      value={upiID}
                      onChange={(e)=>{setUpiID(e.target.value); localStorage.setItem('IDforTransaction',e.target.value);}}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please choose a Upi Id.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustomAppName">
                  <Form.Label>Select App</Form.Label>
                  <InputGroup hasValidation>
                    <ButtonGroup className="w-100">
                      {["GPay", "BhimPay"].map((app) => (
                        <ToggleButton
                          key={app}
                          id={`radio-app-${app}`}
                          type="radio"
                          variant={
                            app === "GPay"
                              ? "outline-danger"
                              : "outline-primary"
                          }
                          name="AppName"
                          value={app}
                          checked={formData.AppName === app}
                          onChange={() => handleAppNameChange(app)}
                          required
                        >
                          {app}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                    <Form.Control.Feedback type="invalid">
                      Please select an app.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
              <div className="text-center mt-3 ">
                <Button
                  onClick={handleFirstPart}
                  className="px-5"
                  style={{ backgroundColor: "blue", borderRadius: "20px" }}
                >
                  Go
                </Button>
              </div>
            </Form>
          </Card.Body>
        )}

        {SecondCardBody && (
          <Card.Body>
            <Row className="mb-3">
              <Form.Group
                as={Col}
                md="4"
                controlId="validationCustomCustomerID"
              >
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Amount"
                  name="Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  required
                />
              </Form.Group>
            </Row>
            <div className="text-center mt-3 ">
              <Button
                onClick={handleFinalSubmit}
                className="px-5"
                style={{ backgroundColor: "blue", borderRadius: "20px" }}
              >
                Submit
              </Button>
            </div>
          </Card.Body>
        )}
        {QRCardBody && (
          <Card.Body className="text-center">
            {QRLink != null && (
              <>
                <QRCode
                  style={{ height: "auto", maxWidth: "10%", width: "10%" }}
                  value={QRLink}
                />
                <div className="mt-3">
                  <a href={GoodlePayDeepLink}>Google</a>
                </div>
                <div className="mt-3">
                  <a href={BHIMDeepLink}>BHIM</a>
                </div>
                <div className="mt-3">
                  <a href={PhonePeDeepLink}>PhonePe</a>
                </div>
                <div className="mt-3">
                  <a href={UPIDeepLink}>UPI</a>
                </div>
                <div className="mt-3">
                  <a href={PaytmDeepLink}>PayTM</a>
                </div>
                <div className="mt-3">
                  <span>
                    Time left: {Math.floor(timeLeft / 60)}:
                    {String(timeLeft % 60).padStart(2, "0")}
                  </span>
                </div>
              </>
            )}
          </Card.Body>
        )}
      </Card>
    </>
  );
}

import './App.css';
import Transaction from './Component/Transaction';


function App() {
  return (
    <div className="App">
     <Transaction/>
    </div>
  );
}

export default App;
